import Axios from "@/config/axios";

export default {
  getAll() {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/buy-requests")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getById(id) {
    return new Promise((resolve, reject) => {
      Axios.get("/admin/buy-requests/" + id + "/show")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createTransaction(id, data) {
    return new Promise((resolve, reject) => {
      Axios.post("/admin/buy-requests/" + id + "/create-transaction", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveNotes(id, data) {
    return new Promise((resolve, reject) => {
      Axios.put("/admin/buy-requests/" + id + "/save-notes", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  definePrice(id, data = { price: 0, products: [] }) {
    return new Promise((resolve, reject) => {
      Axios.put("/admin/buy-requests/" + id + "/define-price", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
